import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Page Not Found" />
      <div className="container my-12">
        <h1>Page Not Found</h1>
        <p className="my-4">You just hit a route that doesn&#39;t exist.</p>
        <p>
          <Link to="/" className="btn btn-primary">
            Return Home
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
